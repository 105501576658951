.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: auto;
  width: 100%;
  bottom: 0;
  text-align: center;
}
.twitter {
  padding: 1em;
  cursor: pointer;
}
.stark {
  zoom: 0.08;
  -moz-transform: scale(0.08);
  padding: 10em;
  cursor: pointer;
}
