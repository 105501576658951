.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-left: 10em;
  padding-right: 10em;
  padding-top: 10em;
}
.image {
  height: 80%;
  width: 80%;
  object-fit: contain;
  cursor: pointer;
}
.text {
  display: flex;
  align-items: center;
  padding-top: 40%;
  height: 100px;
  width: 100%;
  font-size: 4vh;
}
.right {
  text-align: right;
}
